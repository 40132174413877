<template>
    <div>
      <div class="banner"
      :style="{backgroundImage: 'url(' + staticUrl+'design/gongxiangdayang/gongxiangdayangbannaer.jpg' + ')'}">
        <div class="width_965">
          <div class="header flex flex-justify-between">
            <div class="header-left flex flex-align-center">
              <div class="hl-logo">
                <img src="../../../assets/images/logo-white.png" alt="">
              </div>
              <div class="hl-text">
              <div>南康家具</div>
              <div>产业智联网</div>
              </div>
            </div>
            <div class="header-right flex">
              <div class="step-jump">
                <a href="#step1">我的服务</a>
              </div>
              <div class="step-jump">
                <a href="#step2">实木工艺</a>
              </div>
              <div class="step-jump">
                <a href="#step5">联系我们</a>
              </div>
              <div class="step-jump">
                <a href="/login">登录</a>
              </div>
            </div>
          </div>
          <div class="banner-text">
            <div>共享打样中心</div>
            <div>共享化的“新智造”</div>
          </div>
          <div @click="dayang()" class="banner-button flex flex-align-center flex-pack-center">
            <div>我要打样<i class="iconfont icon-youjiantou"></i></div>
          </div>
        </div>
      </div>
      <div id="step1" class="block-1 width_1200">
        <div class="block-title">我们的服务</div>
        <div class="block-content flex flex-justify-between">
          <div class="block-1-item"
            :style="{backgroundImage: 'url(' + staticUrl+'design/gongxiangdayang/fuwu1.jpg' + ')'}">
            <div class="b1i-masking">
              <div class="b1i-img">
                <img :src="staticUrl+'design/gongxiangdayang/icon1.png'">
              </div>
              <div class="b1i-text">
                <h3>木工出样</h3>
                <p>尺寸精准，造型吻合，工艺水准有保证，5天完美出样。</p>
              </div>
            </div>
          </div>
          <div class="block-1-item"
            :style="{backgroundImage: 'url(' + staticUrl+'design/gongxiangdayang/fuwu2.jpg' + ')'}">
            <div class="b1i-masking">
              <div class="b1i-img">
                <img :src="staticUrl+'design/gongxiangdayang/icon1.png'">
              </div>
              <div class="b1i-text">
                <h3>喷漆出样</h3>
                <p>专业设备齐全，使得家具平整度好、手感舒适有质感，色泽均匀，7天出样。</p>
              </div>
            </div>
          </div>
          <div class="block-1-item"
            :style="{backgroundImage: 'url(' + staticUrl+'design/gongxiangdayang/fuwu3.jpg' + ')'}">
            <div class="b1i-masking">
              <div class="b1i-img">
                <img :src="staticUrl+'design/gongxiangdayang/icon1.png'">
              </div>
              <div class="b1i-text">
                <h3>打样工艺</h3>
                <p>无缝对接工厂工艺，专业的人，专业的设备，品质值得信赖</p>
              </div>
            </div>
          </div>
          <div class="block-1-item"
            :style="{backgroundImage: 'url(' + staticUrl+'design/gongxiangdayang/fuwu4.jpg' + ')'}">
            <div class="b1i-masking">
              <div class="b1i-img">
                <img :src="staticUrl+'design/gongxiangdayang/icon1.png'">
              </div>
              <div class="b1i-text">
                <h3>视频看样</h3>
                <p>在线视屏看样，无需亲临现场，便捷高效，满意再付尾款。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="step2" class="block-2 width_1200">
        <div class="block-title">实木榫卯结构工艺展示</div>
        <div class="block-content flex flex-justify-between">
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/caoshou.gif'">
            </div>
            <div class="b2i-text">抄手榫</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/houban.gif'">
            </div>
            <div class="b2i-text">厚板闷榫</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/huxingshizi.gif'">
            </div>
            <div class="b2i-text">弧形直材十字交叉</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/pingban.gif'">
            </div>
            <div class="b2i-text">平板明榫角结合</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/zouma.gif'">
            </div>
            <div class="b2i-text">走马销</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/shuangzhong.gif'">
            </div>
            <div class="b2i-text">双粽角榫</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/yandai.gif'">
            </div>
            <div class="b2i-text">烟袋锅榫</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/yuanfang.gif'">
            </div>
            <div class="b2i-text">圆方结合裹腿</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/zhanbiandacao.gif'">
            </div>
            <div class="b2i-text">攒边打槽装板</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/jiaochajiehe.gif'">
            </div>
            <div class="b2i-text">直材交叉结合</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/dacao.gif'">
            </div>
            <div class="b2i-text">圆香几攒边打槽</div>
          </div>
          <div class="block-2-item">
            <div class="b2i-img">
              <img :src="staticUrl+'design/gongxiangdayang/sunmaogif/dingzijiehe.gif'">
            </div>
            <div class="b2i-text">圆柱丁字结合榫</div>
          </div>
        </div>
      </div>
      <div class="block-3 width_1200">
        <div class="block-title">打样案例</div>
        <div class="block-content">
          <el-carousel :interval="4000" type="card" height="318px">
            <el-carousel-item v-for="(item,idx) in imgList1" :key="idx">
              <!-- <h3 class="medium">{{ item }}</h3> -->
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="block-3 width_1200">
        <div class="block-title">打样案例</div>
        <div class="block-content">
          <el-carousel :interval="4000" type="card" height="318px">
            <el-carousel-item v-for="(item,idx) in imgList2" :key="idx">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div id="step5" class="block-5 width_1200">
        <div class="block-title">联系我们</div>
        <div class="block-content flex">
          <div class="b5-left">
            <div class="flex" style="margin-bottom:30px">
              <div class="b5l-item flex flex-align-center" style="width:calc(50% - 15px);margin-right:15px">
                <div>
                  <img :src="staticUrl+'design/gongxiangdayang/icon13.png'">
                </div>
                <div class="flex-1">
                  <div>联系电话</div>
                  <div>19970936393</div>
                </div>
              </div>
              <div class="b5l-item flex flex-align-center" style="width:calc(50% - 15px);margin-left:15px">
                <div>
                  <img :src="staticUrl+'design/gongxiangdayang/icon4.png'">
                </div>
                <div class="flex-1">
                  <div>工作时间</div>
                  <div>9：00-18：00</div>
                </div>
              </div>
            </div>
            <div class="b5l-item flex flex-align-center">
              <div>
                <img :src="staticUrl+'design/gongxiangdayang/icon2%20.png'">
              </div>
              <div class="flex-1">
                <div>公司地址</div>
                <div>江西省赣州市南康区东山街道金融中心城发集团2号楼6楼</div>
              </div>
            </div>
          </div>
          <div class="b5-right">
            <div class="b5-right-title">加客服，领打样优惠券</div>
            <div class="b5-right-img">
              <img :src="staticUrl+'design/gongxiangdayang/xiaohui18979787390.jpg'">
              <img :src="staticUrl+'design/gongxiangdayang/xiaohui18979787390.jpg'">
            </div>
          </div>
        </div>
        <div class="block-footer">
          <div>版权所有</div>
          <div>Copyright@2020 All Rights Reserved</div>
        </div>
      </div>
    </div>
</template>
<script>
import { throttle } from "lodash";
// import { STATIC_URL_PRE } from '@/config';
const STATIC_URL_PRE = "http://cdn.file.nk.emergen.cn/"; 
export default {
  data() {
    return {
      staticUrl:STATIC_URL_PRE,
      imgList1:[
        STATIC_URL_PRE+"design/gongxiangdayang/imgshangping1.jpg?111",
        STATIC_URL_PRE+"design/gongxiangdayang/imgshangping1.jpg?111",
        STATIC_URL_PRE+"design/gongxiangdayang/imgshangping2.jpg?111",
      ],
      imgList2:[
        STATIC_URL_PRE+"design/gongxiangdayang/imgshangping5.jpg?111",
        STATIC_URL_PRE+"design/gongxiangdayang/imgshangping6.jpg?111",
        STATIC_URL_PRE+"design/gongxiangdayang/imgshangping4.jpg?111",
      ],
      scrollTop:0,
    };
  },
  created() {
    
  },
  mounted() {
    // 锚点滑动
    // $('#top').click(function () {
    //     $("html, body").animate({
    //         scrollTop: 0 + "px"
    //     }, 400, "swing");
    // });
  },
  methods: {
    dayang() {
      this.$router.push("/want/wantIssue");
    }
  },
}
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
  .width_965 {
    width: 965px;
    margin: 0 auto;
  }
  .width_1200 {
    width: 1200px;
    margin: 0 auto 85px;
    .block-title {
      text-align: center;
      padding: 55px 0;
      font-size:48px;
      font-weight:400;
      line-height:65px;
      color:#080245;
    }
  }
  .banner {
    min-width: 1200px;
    margin: 0 auto;
    height: 800px;
    background-position: center;
    background-size: cover;
    position: relative;
    .header {
      line-height: 50px;
      padding-top: 20px;
      .header-left {
        height: 50px;
        line-height: 25px;
        .hl-logo img {
          width: 50px;
          height: 50px;
        }
        .hl-text {
          // width: 72px;
          font-size:18px;
          color: #fff;
          font-weight:bold;
          line-height: 25px;
          margin-left: 25px;
          >div:last-child {
            font-size: 14px;
            font-weight:400;
            line-height:20px;
            border-top: 1px solid #fff;
          }
        }
      }
      .header-right {
        >div {
          padding: 0 20px;
          font-size:18px;
          color: #fff;
          font-weight:bold;
          line-height:50px;
          cursor: pointer;
          a {
            display: block;
            color: #fff;
            &:hover {
              color: #5074EE;
            }
          }
        }
      }
    }
    .banner-text {
      padding-top: 220px;
      font-size:90px;
      color: #fff;
      font-weight:400;
      line-height:126px;
      >div:last-child {
        font-size:24px;
        font-weight:400;
        line-height:33px;
        color:#fff;
        letter-spacing:30px;
      }
    }
    .banner-button {
      margin-top: 50px;
      width:240px;
      background:#fff;
      border-radius:4px;
      font-size:32px;
      color: #5074EE;
      cursor: pointer;
      >div {
        height:65px;
        line-height:65px;
        i {
          font-size: 21px;
          color: #5074EE;
          margin-left: 20px;
          position: relative;
          top: -3px;
        }
      }
    }
  }
  .block-1 {
    .block-1-item {
      width: 287px;
      height: 354px;
      text-align: center;
      background-position: center;
      background-size: cover;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      .b1i-masking {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition:all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        .b1i-img {
          padding: 78px 0 100px 0;
          img {
            width: 50px;
            height: 50px;
          }
        }
        .b1i-text {
          width: 200px;
          margin: 0 auto;
          >h3 {
            font-size:24px;
            color: #fff;
            line-height:32px;
            margin-bottom: 18px;
          }
          p {
            text-align: left;
            font-size:14px;
            color: #fff;
            line-height:17px;
            margin-bottom: 0;
          }
        }
      }
      &:hover .b1i-masking{
        opacity: 1;
      }
    }
  }
  .block-2 {
    .block-2-item {
      width: 287px;
      height: 246px;
      margin-bottom: 32px;
      img {
        width: 287px;
        height: 186px;
      }
      .b2i-text {
        padding: 18px 0;
        text-align: center;
        font-size:24px;
        font-weight:bold;
      }
    }
  }
  .block-3 {
    .el-carousel__item {
      box-shadow:0px 2px 6px rgba(0,0,0,0.16);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .block-5 {
    width: 100%;
    min-width: 1200px;
    background: #F5F5F5;
    margin-bottom: 0;
    .block-content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 50px;
      border-bottom: 2px solid #CCCCCC;
    }
    .b5-left {
      width: 620px;
      .b5l-item {
        padding: 30px 40px;
        background: #fff;
        box-shadow:0px 2px 4px rgba(0,0,0,0.16);
        border-radius:10px;
        font-size: 18px;
        color: #808080;
        line-height:24px;
        img {
          width: 42px;
          height: 42px;
          margin-right: 30px;
        }
        >div>div:first-child {
          margin-bottom:8px;
        }
      }
    }
    .b5-right {
      padding-left: 160px;
      .b5-right-title {
        font-size: 14px;
        color: #808080;
        line-height: 24px;
        padding: 10px 0 30px 0;
      }
      .b5-right-img {
        text-align: center;
        // padding-left: 20px;
        img {
          width: 170px;
          height: 170px;
          margin-right: 23px;
        }
        img:last-child {
          margin-right: 0;
        }
      }
    }
    .block-footer {
      width: 1200px;
      margin: 0 auto;
      padding: 15px 0 25px 0;
      text-align: center;
      font-size: 14px;
      color: #808080;
      line-height: 30px;
    }
  }
</style>